/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ui-radios': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 2.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zM0 12a3 3 0 116 0 3 3 0 01-6 0m7-1.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm0-5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m0 8a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5M3 1a3 3 0 100 6 3 3 0 000-6m0 4.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3"/>',
    },
});
